// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


require("packs/helper.js")



// Trix //
var Trix  = require("trix")
Trix.config.attachments.preview.caption = {
  name: false, size: false
}
// IMPORT ActionText's upload for Trix
import "actiontext"

Trix.config.blockAttributes.heading2 = {
  tagName: "h2",
  terminal: true,
  breakOnReturn: true,
  group: false
}
Trix.config.blockAttributes.heading3 = {
  tagName: "h3",
  terminal: true,
  breakOnReturn: true,
  group: false
}


var h2ButtonHTML = '<button type="button" class="trix-button trix-button-heading-2" data-trix-attribute="heading2" title="Subheading">H2</button>'
var h3ButtonHTML = '<button type="button" class="trix-button trix-button-heading-3" data-trix-attribute="heading3" title="Subheading">H3</button>'

addEventListener("trix-initialize", function(event) {

    event.target.toolbarElement.querySelector(".trix-button--icon-heading-1").insertAdjacentHTML("afterend", h2ButtonHTML)
    event.target.toolbarElement.querySelector(".trix-button-heading-2").insertAdjacentHTML("afterend", h3ButtonHTML)
})
