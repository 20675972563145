import { DirectUpload } from "@rails/activestorage"

export class AttachmentUpload {
  constructor(attachment, element, form) {
    this.attachment = attachment
    this.element = element
    this.form = form
    this.directUpload = new DirectUpload(attachment.file, this.directUploadUrl, this)
  }

  start() {
    this.directUpload.create(this.directUploadDidComplete.bind(this))
  }

  directUploadWillStoreFileWithXHR(xhr) {
    xhr.upload.addEventListener("progress", event => {
      const progress = event.loaded / event.total * 100
      this.attachment.setUploadProgress(progress)
    })
  }

  directUploadDidComplete(error, attributes) {
    if (error) {
      throw new Error(`Direct upload failed: ${error}`)
    }

    this.attachment.setAttributes({
      sgid: attributes.attachable_sgid,
      key: attributes.key,
      blob_id: attributes.id,
      url: this.createBlobUrl(attributes.signed_id, attributes.filename),
      href: this.createBlobUrl(attributes.signed_id, attributes.filename)
    })

    this.appendHiddenAttributeWithSgid(attributes.key)
  }

  appendHiddenAttributeWithSgid(key) {
    var input = document.createElement("input")
    input.setAttribute("type", "hidden")
    input.setAttribute("value", key)
    input.setAttribute("name", "attached_blob[]")

    this.form.appendChild(input)
  }

  createBlobUrl(signedId, filename) {

    var t = this.blobUrlTemplate
      .replace(":signed_id", signedId)
      .replace(":filename", encodeURIComponent(filename))

    return t
  }

  get directUploadUrl() {
    return this.element.dataset.directUploadUrl
  }

  get blobUrlTemplate() {
    var t = this.element.dataset.blobUrlTemplate
    return this.element.dataset.blobUrlTemplate
  }
}
