$(document).on("ready turbolinks:load", function() {
  $("[data-toggle-hidden]").click(function() {
    var c = $(this).data('toggle-hidden')
    //console.log(c)
    $(c).toggleClass('d-none')
    //console.log(c)
  })
})


$(window).scroll(function() {
    // if ($(window).scrollTop() > 30) {
    //     $('nav.site-header').addClass('shadow');
    // } else {
    //     $('nav.site-header').removeClass('shadow');
    // }
});
