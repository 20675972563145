
import { AttachmentUpload } from "./attachments_upload"

document.addEventListener("trix-file-accept", function(e) {
  var target = e.target

  // disable uploads if allowUploads is not defined
  if (typeof target.dataset.allowUploads == 'undefined') {
    e.preventDefault()
  }
})

document.addEventListener("trix-attachment-add", function(e) {
  var attachment = e.attachment
  var target = e.target
  var form = target.closest('form')


  if (attachment.file) {
    const upload = new AttachmentUpload(attachment, target, form)
    upload.start()
  }
})

document.addEventListener("trix-attachment-remove", function(e) {
  var attachment = e.attachment
  var target = e.target
  var form = target.closest('form')
  var key = attachment.getAttribute("key")
  var to_remove  = document.querySelector("input[value='" + key + "']")

  if (to_remove != null){
    to_remove.remove()
  }

  // save it as detached, for proper processing
  var input = document.createElement("input")
  input.setAttribute("type", "hidden")
  input.setAttribute("value", key)
  input.setAttribute("name", "detached_blob[]")

  form.appendChild(input)
})
